import React, { useEffect, useState } from "react";
import Table from "../common/Table";
import {
  JavaApiPost,
  downloadJavaEndPointWithSecreteKey,
  getResellerId,
} from "../../lib/AppHelper";
import { Box, CircularProgress, Tooltip, Typography } from "@mui/material";
import Main from "../../screens/Main";
import { AppBackButton, AppHeading } from "../AppComponents";
import { useHistory, useLocation } from "react-router-dom";
import { useRef } from "react";
import { FaCodeBranch, FaCheckCircle, FaDownload } from "react-icons/fa";
import { Mail } from "@mui/icons-material";
import { toast } from "react-toastify";

const ExcelUploadPolicyReport = (props) => {
  const location = useLocation();
  const reseller_id = location?.state && location?.state?.id;
  const ref = useRef(null);
  const renderComponent = location?.state && location?.state?.componentTo;
  const type = location?.state?.type;
  const MessageRef = React.useRef();
  const history = useHistory();
  const [filters, setFilters] = useState({
    page: 0,
    limit: 10,
  });
  const [totalCount, setTotalCount] = useState(10);
  const [uploadsRecords, setUploadsRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [onGoingUploads, setOnGoingUploads] = useState([]);
  const [successLoader, setSuccessLoader] = useState(false);
  const [failedLoader, setFailedLoader] = useState(false);
  const [totalRecordLoader, setTotalRecordLoader] = useState(false);

  const calPercentage = ({
    failCount = 0,
    successCount = 0,
    totalCount = 3000,
  }) => {
    const firstVal = parseInt(failCount) + parseInt(successCount);
    if (totalCount == 0) {
      return 0;
    } else {
      return parseInt(Math.round((firstVal / parseInt(totalCount)) * 100));
    }
  };

  const header = [
    { label: "File Name", dataKey: "fileName" },
    {
      label: "Successful Record",
      dataKey: "successCount",
      cell: (row) => {
        return (
          <div className="flex gap-2 justify-center items-center">
            <span>{row?.successCount}</span>
            {parseInt(row?.successCount) > 0 &&
              (successLoader === row?.excelProcessId ? (
                <CircularProgress className="h-3 w-3" size={"xs"} />
              ) : (
                <Tooltip
                  disableFocusListener
                  disableTouchListener
                  title={<span>download</span>}
                >
                  <button>
                    <FaDownload
                      className="text-green-500 cursor-pointer"
                      onClick={() => downloadSucessRecord(row)}
                    />
                  </button>
                </Tooltip>
              ))}
          </div>
        );
      },
    },
    {
      label: "Failed Record",
      dataKey: "failCount",
      cell: (row) => {
        return (
          <div className="flex gap-2 justify-center items-center">
            <span>{row?.failCount}</span>
            {parseInt(row?.failCount) > 0 &&
              (failedLoader === row?.excelProcessId ? (
                <CircularProgress className="h-3 w-3" size={"xs"} />
              ) : (
                <Tooltip
                  disableFocusListener
                  disableTouchListener
                  title={<span>download</span>}
                >
                  <button>
                    <FaDownload
                      className="text-primary-500 cursor-pointer"
                      onClick={() => downloadFailedRecord(row)}
                    />
                  </button>
                </Tooltip>
              ))}
          </div>
        );
      },
    },
    {
      label: "Total Record",
      dataKey: "totalCount",
      cell: (row) => {
        return (
          <div className="flex gap-2 justify-center items-center">
            <span>{row?.totalCount}</span>
            {parseInt(row?.totalCount) > 0 &&
              (totalRecordLoader === row?.excelProcessId ? (
                <CircularProgress className="h-3 w-3" size={"xs"} />
              ) : (
                <Tooltip
                  disableFocusListener
                  disableTouchListener
                  title={<span>download</span>}
                >
                  <button>
                    <FaDownload
                      className="text-blue-500 cursor-pointer"
                      onClick={() => downloadAllRecordExcel(row)}
                    />
                  </button>
                </Tooltip>
              ))}
          </div>
        );
      },
    },
    {
      label: "Uploaded On",
      dataKey: "createdOn",
      type: "date",
      format: "DD-MM-YYYY",
    },
    { label: "Uploaded By", dataKey: "resellerName" },
  ];

  const fetchUploadedRecord = async () => {
    setLoading(true);
    const resellerId = reseller_id || getResellerId();
    const data = {
      ...filters,
      resellerId: resellerId,
      excelType: location?.state?.type,
    };
    const res = await JavaApiPost(
      "enrollment-service/uploader/getExcelUploaderHistoryData",
      data
    );
    if (res?.responseObject) {
      setUploadsRecords([...res.responseObject]);
      setTotalCount(res.count);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const fetchOngoingRecord = async () => {
    const resellerId = reseller_id || getResellerId();
    const data = {
      resellerId: resellerId,
      page: 0,
      limit: 4,
      status: "",
      excelType: location?.state?.type,
    };
    const res = await JavaApiPost(
      `enrollment-service/uploader/getExcelInProgressUploaderDetails`,
      data
    );
    if (res?.responseObject?.length > 0) {
      ref.current = [...res.responseObject];
      setOnGoingUploads([...res.responseObject]);
    } else {
      if (ref.current?.length) {
        fetchUploadedRecord();
        setOnGoingUploads([]);
        ref.current = [];
      }
    }
  };

  useEffect(() => {
    fetchUploadedRecord();
    let timer;
    clearInterval(timer);
    fetchOngoingRecord();
    timer = setInterval(() => {
      fetchOngoingRecord();
    }, 5000);
    return () => {
      clearInterval(timer);
    };
  }, [filters]);

  const onPageHandler = (page) => {
    setFilters({
      ...filters,
      page: page - 1,
    });
  };

  const getStepCountByStatus = (status) => {
    let step = 2;
    switch (status) {
      case "REQUESTED":
        step = 2;
        break;
      case "PENDING":
        step = 3;
        break;
      case "SENT":
        step = 4;
        break;
      default:
        return 2;
    }
    return step;
  };

  const getTitle = () => {
    switch (type) {
      case "normal uploader":
        return "Excel Uploader History";
      case "group uploader":
        return "Group Uploader History";
      case "credit uploader":
        return "Credit Uploader History";
      case "emi uploader":
        return "Emi Uploader History";
      case "lap uploader":
        return "Loan Against Property Uploader History";
      case "policy uploader":
        return "Vendor Policy Uploader History";

      default:
        return "Uploader History";
    }
  };

  const downloadSucessRecord = async (row) => {
    const { excelProcessId } = row;
    setSuccessLoader(excelProcessId);
    const resellerId = reseller_id || getResellerId();
    const res = await downloadJavaEndPointWithSecreteKey(
      `enrollment-service/uploader/api/get_excel_uploader_history_success_data_with_preview?reseller_id=${resellerId}&excel_process_record_id=${excelProcessId}&excel_type=${type}`,
      `${row.fileName}`.split(".")?.[0] + "_success_record.xlsx"
    );
    if (res) {
      toast.success("Success record download successfully");
      setSuccessLoader(false);
    } else {
      setSuccessLoader(false);
    }
  };

  const downloadFailedRecord = async (row) => {
    const { excelProcessId } = row;
    setFailedLoader(excelProcessId);
    const resellerId = reseller_id || getResellerId();
    const res = await downloadJavaEndPointWithSecreteKey(
      `enrollment-service/uploader/api/get_excel_uploader_history_failure_data_with_preview?reseller_id=${resellerId}&excel_process_record_id=${excelProcessId}&excel_type=${type}`,
      `${row.fileName}`.split(".")?.[0] + "_failed_record.xlsx"
    );
    if (res) {
      toast.success("Failed record download successfully");
      setFailedLoader(false);
    } else {
      setFailedLoader(false);
    }
  };

  const downloadAllRecordExcel = async (row) => {
    const { excelProcessId } = row;
    setTotalRecordLoader(excelProcessId);
    const resellerId = reseller_id || getResellerId();
    const res = await downloadJavaEndPointWithSecreteKey(
      `enrollment-service/uploader/api/get_excel_uploader_history_total_data_with_preview?reseller_id=${resellerId}&excel_process_record_id=${excelProcessId}&excel_type=${type}`,
      `${row.fileName}`.split(".")?.[0] + "_total_record.xlsx"
    );
    if (res) {
      toast.success("All record download successfully");
      setTotalRecordLoader(false);
    } else {
      setTotalRecordLoader(false);
    }
  };

  return (
    <Main MessageRef={MessageRef}>
      <AppBackButton to="excel-uploader" />
      <AppHeading className="text-center text-primary-500">
        {getTitle()}
      </AppHeading>

      {onGoingUploads.length > 0 && (
        <div className="border-2 p-1 mt-4">
          <div className="p-2 h-12 flex items-center text-white bg-primary">
            Current Ongoing Uploads
          </div>
          <div className="mt-4 p-4">
            {onGoingUploads?.map((item, index) => {
              return (
                <div className="bg-gray-100 rounded-xl p-2 my-4">
                  <ul
                    key={index}
                    className="relative flex flex-row gap-x-2 w-full mt-1 overflow-scroll  md:overflow-hidden"
                  >
                    <li className="flex items-center gap-x-2 shrink basis-0 flex-1 group">
                      <div className="min-w-7 min-h-7 inline-flex justify-center items-center text-xs align-middle">
                        <FaCheckCircle className="h-6 w-6 text-green-500" />
                        <span className="ms-2 block text-sm font-medium text-blue-500 dark:text-white ml-3">
                          File upload
                        </span>
                      </div>
                      <div
                        className={`w-full h-px flex-1 ${
                          getStepCountByStatus(item.realTimeStatus) > 1
                            ? "bg-blue-400"
                            : "bg-gray-300"
                        } group-last:hidden dark:bg-neutral-700`}
                      ></div>
                    </li>

                    <li className="flex items-center gap-x-2 shrink basis-0 flex-1 group">
                      <div className="min-w-7 min-h-7 inline-flex justify-center items-center text-xs align-middle">
                        <span className="size-7 flex justify-center items-center flex-shrink-0 bg-white border border-gray-200 font-medium text-gray-800 rounded-full dark:bg-neutral-900 dark:border-neutral-700 dark:text-white">
                          {getStepCountByStatus(item.realTimeStatus) > 1 ? (
                            <FaCheckCircle className="h-6 w-6 text-green-500" />
                          ) : (
                            <FaCodeBranch className="h-6 w-6 text-gray-500" />
                          )}
                        </span>
                        <span
                          className={`ms-2 block text-sm font-medium ${
                            getStepCountByStatus(item.realTimeStatus) > 1
                              ? "text-blue-500"
                              : "text-gray-500"
                          } dark:text-white ml-3`}
                        >
                          Requested
                        </span>
                      </div>
                      <div
                        className={`w-full h-px flex-1 ${
                          getStepCountByStatus(item.realTimeStatus) > 2
                            ? "bg-blue-400"
                            : "bg-gray-300"
                        } group-last:hidden dark:bg-neutral-700`}
                      ></div>
                    </li>

                    <li className="flex items-center gap-x-2 shrink basis-0 flex-1 group">
                      <div className="min-w-7 min-h-7 inline-flex justify-center items-center text-xs align-middle">
                        {getStepCountByStatus(item.realTimeStatus) > 3 ? (
                          <FaCheckCircle className="h-6 w-6 text-green-500" />
                        ) : (
                          <Box
                            sx={{
                              position: "relative",
                              display: "inline-flex",
                            }}
                          >
                            <CircularProgress
                              variant="determinate"
                              value={calPercentage(item)}
                            />
                            <Box
                              sx={{
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                position: "absolute",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Typography
                                variant="caption"
                                component="div"
                                color="text.secondary"
                              >
                                {calPercentage(item)}%
                              </Typography>
                            </Box>
                          </Box>
                        )}

                        <span
                          className={`ms-2 block text-sm font-medium ${
                            getStepCountByStatus(item.realTimeStatus) > 2
                              ? "text-blue-500"
                              : "text-gray-500"
                          } dark:text-white ml-3`}
                        >
                          In progress
                        </span>
                      </div>
                      <div
                        className={`w-full h-px flex-1 ${
                          getStepCountByStatus(item.realTimeStatus) > 3
                            ? "bg-blue-400"
                            : "bg-gray-300"
                        } group-last:hidden dark:bg-neutral-700`}
                      ></div>
                    </li>
                    <li className="flex items-center gap-x-2 shrink basis-0 ">
                      <div className="min-w-7 min-h-7 inline-flex justify-center items-center text-xs align-middle">
                        <span className="size-7 flex justify-center items-center flex-shrink-0 bg-white font-medium text-gray-800">
                          {getStepCountByStatus(item.realTimeStatus) > 3 ? (
                            <FaCheckCircle className="h-6 w-6 text-green-500" />
                          ) : (
                            <Mail />
                          )}
                        </span>
                        <span
                          className={`ms-2 block text-sm font-medium ${
                            getStepCountByStatus(item.realTimeStatus) > 3
                              ? "text-blue-500"
                              : "text-gray-500"
                          } dark:text-white ml-3`}
                        >
                          Mail sent
                        </span>
                      </div>
                    </li>
                  </ul>
                  <div className="flex text-xs gap-2 md:gap-20 flex-wrap mt-2">
                    <span>Total record : {item?.totalCount}</span>
                    <span>Success record : {item?.successCount}</span>
                    <span>Failed record : {item?.failCount}</span>
                    <span>
                      Status :{" "}
                      {item?.realTimeStatus.charAt(0).toUpperCase() +
                        item?.realTimeStatus.slice(1).toLowerCase()}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}

      <div className="flex flex-row-reverse flex-end">
        <button
          className="bg-primary-500 text-white p-2 rounded-xl mt-5"
          onClick={() => {
            history.push({ pathname: renderComponent, state: location?.state });
          }}
        >
          Upload Excel
        </button>
      </div>
      <Table
        data={uploadsRecords}
        headers={header}
        page={filters.page + 1}
        limit={filters.limit}
        total={totalCount}
        onPageChange={onPageHandler}
        loader={loading}
      />
    </Main>
  );
};

export default ExcelUploadPolicyReport;
