import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import AdminMain from "./AdminMain";
import {
  RiUserStarFill,
  RiUserAddFill,
  RiUserFollowFill,
  RiUserSettingsFill,
  RiUserFollowLine,
  RiFileUserFill,
  RiShoppingBasketFill,
  RiFileExcel2Fill,
} from "react-icons/ri";
import EmailIcon from "@mui/icons-material/Email";

const DashboardIcon = ({ title, path, icon, classes = null, state }) => {
  return (
    <Link
      to={{
        pathname: path,
        state: state,
      }}
      className={
        classes +
        " ml-0 pl-0 mb-5 border border-primary rounded-xl w-40 h-40 flex justify-center items-center md:justy-hover:bg-primary-50"
      }
    >
      <div>
        <div className="flex justify-center items-center text-primary">
          {icon}
        </div>
        <div className="text-xs text-center uppercase font-bold mt-4">
          {title}
        </div>
      </div>
    </Link>
  );
};
export class AdminDashboard extends Component {
  MessageRef = React.createRef();
  render() {
    const { permissions, isSuperAdmin } = this.props;

    const menuList = [
      {
        title: "Manage",
        subTitle: "Resellers",
        to: "resellers",
        icon: <RiUserStarFill size={40} />,
        role: "manage_resellers",
      },
      {
        title: "Add",
        subTitle: "MLM Reseller",
        to: "add-mlm",
        icon: <RiUserAddFill size={40} />,
        role: "add_mlm_reseller",
      },
      {
        title: "Add",
        subTitle: "MFI Reseller",
        to: "add-mfi",
        icon: <RiUserFollowFill size={40} />,
        role: "add_mfi_reseller",
      },
      {
        title: "Add",
        subTitle: "Corporate",
        to: "add-corporate",
        icon: <RiUserFollowLine size={40} />,
        role: "add_corporate_reseller",
      },
      {
        title: "Reseller's",
        subTitle: "Products",
        to: "reseller-products",
        icon: <RiUserSettingsFill size={40} />,
        role: "reseller_product",
      },
      {
        title: "Reseller's",
        subTitle: "Report",
        to: "reseller-reports",
        icon: <RiFileUserFill size={40} />,
        role: "reseller_report",
      },
      {
        title: "Add/Assign",
        subTitle: "Roles",
        to: "roles",
        icon: <RiFileUserFill size={40} />,
        role: "role_assign",
      },
      {
        title: "Add",
        subTitle: "Product",
        to: "add-product",
        icon: <RiShoppingBasketFill size={40} />,
        role: "add_product",
      },
      {
        title: "Clone",
        subTitle: "Product",
        to: "clone-product",
        icon: <RiShoppingBasketFill size={40} />,
        role: "clone_product",
      },
      {
        title: "Excel",
        subTitle: "Uploader",
        to: "admin-uploader",
        state: {
          componentTo: "/excel-uploader",
          type: "normal uploader",
        },
        icon: <RiFileExcel2Fill size={40} />,
        role: "upload_excel",
      },
      {
        title: "Group",
        subTitle: "Uploader",
        to: "admin-uploader",
        state: {
          componentTo: "/group-excel-uploader",
          type: "group uploader",
        },
        icon: <RiFileExcel2Fill size={40} />,
        role: "group_upload",
      },
      {
        title: "Credit Life",
        subTitle: "Uploader",
        to: "admin-uploader",
        icon: <RiFileExcel2Fill size={40} />,
        role: "credit_upload",
        state: {
          componentTo: "/credit-life-uploader",
          type: "credit uploader",
        },
      },

      {
        title: "Reimbursement Mailer",
        to: "/reimbursement-mailer",
        icon: <EmailIcon fontSize="large" />,
        role: "claim_notification",
      },
    ];

    return (
      <AdminMain MessageRef={this.MessageRef}>
        {permissions && Object.keys(permissions).length > 0 ? (
          <div className="flex flex-wrap justify-center items-center gap-10">
            {menuList.map((item, index) => {
              if (isSuperAdmin || permissions?.[item.role]) {
                return (
                  <DashboardIcon
                    key={index}
                    title={
                      <React.Fragment>
                        <p>{item.title}</p>
                        <p>{item.subTitle}</p>
                      </React.Fragment>
                    }
                    path={item.to}
                    icon={item.icon}
                    state={item.state}
                  />
                );
              }
            })}
          </div>
        ) : (
          <div className="text-red-500">
            <h4>You don't have permission to view admin dashboard</h4>
          </div>
        )}
      </AdminMain>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, isSuperAdmin } = state;
  return {
    permissions: user.permissions,
    isSuperAdmin,
  };
};

const AdminDashboardConnect = connect(mapStateToProps)((props) => {
  return <AdminDashboard {...props} />;
});

export default AdminDashboardConnect;
